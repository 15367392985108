.img {
  width: 100%;
  aspect-ratio: 1;
  /* Устанавливаем максимальную ширину изображения */
  height: 100%;
  /* Автоматически подстраиваем высоту под измененную ширину */
}

.input-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 100%;
  aspect-ratio: 1;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  height: 100%;
}

.flex {
  display: flex;
  align-items: center;
  color: rgb(182, 182, 182);
  width: 500px;
}

.Internal {
  display: flex;
  flex-direction: column;
}

.p {
  font-size: 13px;
  color: rgb(182, 182, 182);
}

.margin {
  margin-left: 3%;
}

.cart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.h3 {
  font-size: 17px;
  color: rgb(182, 182, 182);
}

.delete {
  position: relative;
  margin-bottom: 1%;
}

.modal {
  width: 80%;
  height: 80%;
  background-color: black;
  border-radius: 12px;
}

.fast_panel {
  background-color: #2d2d2d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  height: 5rem;
  margin-top: 1rem;
  padding: 1.2rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    125deg,
    rgba(2, 0, 36, 1) 2%,
    rgba(9, 9, 121, 1) 70%,
    rgba(0, 212, 255, 1) 150%
  );
  box-shadow: 0px 0px 10px 0px #2d2d2d;
}

.fast_carts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}
.request-chat-add-inf {
  display: grid;
  grid-template-rows: 15rem 12rem 45rem 3rem;
  gap: 1rem;
}

.container_panel {
  width: 100%;
  margin-bottom: 20px;
  background-color: #2d2d2d;
  border-radius: 10px;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 4rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    135deg,
    rgba(2, 0, 36, 1) 10%,
    rgba(9, 9, 121, 1) 100%,
    rgba(0, 212, 255, 1) 160%
  );
  box-shadow: 0px 0px 10px 0px #2d2d2d;
}

.text_field_panel {
  height: 100%;
}

.MuiInputBase-fullWidth {
  height: 100%;
}

.css-18ax2bx-MuiInputBase-input-MuiOutlinedInput-input {
  height: 100% !important;
}

.dis {
  width: 97%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
}

.text_panel {
  margin-left: 2%;
  padding-top: 2%;
  margin-right: 2%;
  padding-bottom: 2%;
  height: 100%;
  display: grid;
  grid-template-rows: 2rem 1fr;
}

.video {
  width: 80%;
  height: 80%;
}

.button_hr {
  margin-left: 30px;
  height: 100%;
}

.button_hr button {
  width: 100%;
  height: 100%;
}

.width {
  width: 100%;
}

@media (max-width: 500px) {
  .fast_carts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 5rem;
  }

  .fast_panel {
    width: 100%;
    height: 100px;
    background-color: #2d2d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
  }
}

.admin-message {
  display: flex;
  justify-content: end;
  align-items: end;
  padding-left: 30px;
}

.user-message {
  display: flex;
  justify-content: start;
  align-items: start;
  padding-right: 30px;
}

.admin-message-description {
  position: absolute;
  bottom: -2.3rem;
  right: 0;
  display: flex;
  gap: 0.5rem;
}

.user-message-description {
  position: absolute;
  bottom: -2.3rem;
  left: 0;
  display: flex;
  gap: 0.5rem;
}
