#list {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.cont {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 60%;
  gap: 2rem;
  justify-content: center;
}

.zak {
  width: 100%;
}
.car {
  display: flex;
  padding: 30px;
  border-radius: 12px;
  margin-bottom: 25px;
  width: 100%;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 20px;
  background-image: linear-gradient(
    to left,
    rgba(0, 36, 197, 0.56),
    rgba(13, 11, 87, 0.61)
  );
  border: 1px solid rgba(62, 62, 62, 0.4);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.38);
  justify-content: center;
  align-items: center;
}

.delete {
  cursor: pointer;
  color: #fff;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.cart {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 80%;
}

.cart h5 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 1rem;
}

.carts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  margin-left: 23%;
}

.display {
  display: flex;
}
