.custom-drawer {
  display: flex;
  flex-direction: column;
}

.custom-drawer-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1rem;
}
