* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    150deg,
    rgba(2, 0, 36, 1) 10%,
    rgba(9, 9, 121, 1) 40%,
    rgba(0, 212, 255, 1) 150%
  );
  background-size: cover;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}
